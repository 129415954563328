//A SCSS file which contains all of the global kendo theme overrides.
//reference: https://www.telerik.com/kendo-angular-ui/components/styling/custom-themes/
//reference: https://www.telerik.com/kendo-angular-ui/components/styling/theme-default/customization/

//Common
$kendo-font-family: "Poppins", sans-serif;

//Dialog
$kendo-dialog-titlebar-bg: var(--bgcolor-primary);
$kendo-dialog-titlebar-text: #FFFFFF;

//tree-view
$kendo-treeview-md-font-size: var(--font-size-medium);
$kendo-treeview-indent: 24px;

$kendo-treeview-item-hover-bg: #F1F1F1;
$kendo-treeview-item-hover-text: #000000;

$kendo-treeview-item-selected-bg: rgba(255, 141, 0, 0.20);
$kendo-treeview-item-selected-text: #000000;

$kendo-treeview-item-focus-shadow: none;

.k-window {
  border-width: 0px !important;
}

.k-window-titlebar-action {
  opacity: 1 !important;
}

.k-treeview-leaf.k-disabled {
  opacity: 1 !important;
}

.k-treeview-leaf:not(.k-disabled) {
  cursor: pointer;
}