* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  font-size: 14px;
}

body {
  font-family: "Poppins", sans-serif;
}

a {
  color: #0065ff;
  text-decoration: none;
}

a:hover {
  color: #427bd1;
}

/***************************************************/
/**** Scrollbar Utillity Classes ****/
/***************************************************/

.scrollbar-gray-1 {
  --scrollbar-color-thumb: var(--color-gray-1);
  --scrollbar-color-track: transparent;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 10px;
}

.scrollbar-gray-4 {
  --scrollbar-color-thumb: var(--color-gray-4);
  --scrollbar-color-track: transparent;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 10px;
}

/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
  .scrollbar-gray-1 {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }
  
  .scrollbar-gray-4 {
      scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
      scrollbar-width: var(--scrollbar-width);
  }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
  .scrollbar-gray-1::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
  }
  .scrollbar-gray-1::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
  }
  .scrollbar-gray-1::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
  }
  
  .scrollbar-gray-4::-webkit-scrollbar-thumb {
      background: var(--scrollbar-color-thumb);
  }
  .scrollbar-gray-4::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
  }
  .scrollbar-gray-4::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
  }
}

/***************************************************/
/**** Color Utillity Classes ****/
/***************************************************/

.bg-gray-1 {
  background-color: var(--color-gray-1) !important;
}

.bg-gray-2 {
  background-color: var(--color-gray-2) !important;
}

.bg-gray-3 {
  background-color: var(--color-gray-3) !important;
}

.bg-gray-4 {
  background-color: var(--color-gray-4) !important;
}

/***************************************************/
/**** General Utillity Classes ****/
/***************************************************/
.overflow-hidden {
  overflow: hidden;
}

.n-p {
  padding: 0;
}

.n-m {
  margin: 0;
}

.text-align-center {
  text-align: center;
}

.text-align-end {
  text-align: end;
}

.display-flex {
  display: flex;
}

.float-right {
  float: right;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-self-center {
  align-self: center !important;
}

.fw-bold {
  font-weight: bold;
}
