/* Extra large screens */
@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }

  .curved-bottom {
    background-position: -87px -210px;
  }
}