// Mode
$mode: default;

// Core
@import 'core/variables';
@import 'core/responsive';
@import 'core/headings';
@import 'core/base';
@import 'core/button';

.toast-top-offset {
    top: 15px !important;
}