.btn:focus {
  outline: 3px solid rgba(0,0,0,0.1)
}

.btn.btn-outline-black {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 4px;
  border: 2px solid var(--color-black);
  color: var(--color-black);

  &:hover {
    border: 2px solid var(--color-black);
    color: var(--color-black);
    opacity: 0.7;
  }
}

.btn.btn-outline-blue {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);

  &:hover {
    border: 2px solid var(--color-blue);
    color: var(--color-blue);
    background-color: #e4ecff;
  }
}

.btn.btn-outline-white {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 4px;
  border: 2px solid var(--color-white);
  color: var(--color-white);

  &:hover {
    border: 2px solid var(--color-white);
    color: var(--color-white);
    opacity: 0.7;
  }
}

.btn.btn-orange {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: var(--color-orange);
  color: var(--color-white);
  border: 2px solid var(--color-orange);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-orange-accent);
    border: 2px solid var(--color-orange-accent);
  }

  &:disabled {
    opacity: 0.7;
    border-color: transparent;
  }
}

.btn.btn-text--orange {
  display: flex;
  align-items: center;
  gap: 5px;

  color: var(--color-orange);
  font-size: var(--font-size-medium-xlarge);

  &:hover {
    color: var(--color-orange);
  }
}

@media (max-width: 767px) { 
  .btn.btn-text--orange {
    font-size: var(--font-size-small-1);

    img {
      max-width: 20px;
    }
  }
}