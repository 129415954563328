h1 {
  font-size: var(--font-size-heading);
}

h2 {
  font-size: var(--font-size-heading);
}

h3 {
  font-size: var(--font-size-xlarge);
}

h4 {
  font-size: var(--font-size-large);
}

h5 {
  font-size: var(--font-size-medium-large);
}

h6 {
  font-size: var(--font-size-base);
}

@media (max-width: 1200px) {
  h1 {
    font-size: var(--font-size-xlarge);
  }

  h2 {
    font-size: var(--font-size-xlarge);
  }

  h3 {
    font-size: var(--font-size-medium-xlarge);
  }

  h4 {
    font-size: var(--font-size-medium-large);
  }

  h5 {
    font-size: var(--font-size-medium);
  }

  h6 {
    font-size: var(--font-size-base-1);
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: var(--font-size-medium-xlarge);
  }

  h2 {
    font-size: var(--font-size-medium-xlarge);
  }

  h3 {
    font-size: var(--font-size-medium-large);
  }

  h4 {
    font-size: var(--font-size-medium);
  }

  h5 {
    font-size: var(--font-size-medium);
  }
}

@media (max-width: 480px) {

  h2 {
    font-size: var(--font-size-medium);
  }

}