//Upgraded to use new AG Grid SASS API https://www.ag-grid.com/angular-data-grid/global-style-upgrading-to-v28-sass/
@use "ag-grid-community/styles" as ag;
@include ag.grid-styles((
  theme: material
));
.ag-theme-material {

  --ag-font-family: "Poppins", sans-serif;
  	
  --ag-material-primary-color: #000000;
  --ag-material-accent-color: var(--color-primary);
  
  --ag-header-foreground-color: #2e2e2e;
  --ag-header-column-resize-handle-display: block;

  --ag-header-background-color: #f8f9fa;

}

//Reference https://www.ag-grid.com/archive/28.0.0/angular-data-grid/global-style-customisation-variables/
.ag-theme-material.ag-theme-dark {
  scrollbar-color: #8b8b8b #000000;

  --ag-foreground-color: #FFFFFF;
  --ag-secondary-foreground-color: #d6d6d6;
  --ag-background-color: #000000;
  --ag-header-foreground-color: #FFFFFF;
  --ag-header-background-color: #1f1f1f;
  --ag-subheader-background-color: #292929;
  --ag-odd-row-background-color: rgba(255, 255, 255, 0.075);

  --ag-material-primary-color: #FFFFFF;
  --ag-material-accent-color: var(--color-primary);

  --ag-modal-overlay-background-color: rgb(255, 255, 255, 0.1);
  --ag-row-hover-color: #1f1f1f;
  --ag-column-hover-color: #1f1f1f;
  --ag-header-cell-hover-background-color: #3d3d3d;

  --ag-border-color: #8b8b8b;
  --ag-header-column-resize-handle-color: #8b8b8b;

  --ag-card-shadow: 5px 5px 10px rgba(125, 125, 125, 0.3);
  --ag-popup-shadow: 5px 5px 10px rgba(125, 125, 125, 0.3);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./app/styles/core/_variables";

@import "bootstrap/scss/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "roboto-fontface/css/roboto/roboto-fontface.css";

@import 'ngx-toastr/toastr';

@import "./app/styles/style";

@import "./app/styles/vendors/kendo";
@import "@progress/kendo-theme-bootstrap/dist/all.scss";

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
