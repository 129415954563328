:root {
    /* Colors */
    --color-primary: #00AAFF;
    --color-primary-accent: #0087ca;
    --color-secondary: #2ecc71;
    --color-midgrey: #615b5b;
    --color-blue: #0079bb;
    --color-lightblue: #009fe3;
    --color-base: #ffffff;
    --color-grey: rgba(255, 255, 255, 0.5);
    --color-dark-primary: #225b89;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-orange: #ff8d00;
    --color-orange-accent: #ffa333;
    --color-darkyellow: #959000;
    --color-darkgreen: #37a604;
    --color-btnblue: #245cd3;
    --color-lightred: #ff5666;
    --color-blue-gray: #7F8DA3;
    --color-credittxt: #135CF9;
    --color-blue-green: #00AC7A;
    
    --color-primary-blue: #00AAFF;
    --color-primary-blue-accent: #0087ca;
    --color-blue: #245cd3;

    --color-gray-1: #E8E8E8;
    --color-gray-2: #8b8b8b;
    --color-gray-3: #4b4b4b;
    --color-gray-4: #2b2b2b;

    /* Font sizes */
    --font-size-base-mobile: 14px;
    --font-size-extrasmall: 0.625rem;
    --font-size-small: 0.75rem;
    --font-size-small-1: 0.875rem;
    --font-size-base: 1rem;
    --font-size-base-1: 1.125rem;
    --font-size-medium: 1.25rem;
    --font-size-medium-large: 1.375rem;
    --font-size-medium-xlarge: 1.5rem;
    --font-size-large: 1.625rem;
    --font-size-xlarge: 1.75rem;
    --font-size-xxlarge: 2rem;
    --font-size-heading: 2.5rem;

    /* Spacing */
    --spacing-small: 0.5rem;
    --spacing-base: 1rem;
    --spacing-large: 2rem;

    /* Border radius */
    --border-radius-small: 4px;
    --border-radius-base: 8px;
    --border-radius-large: 16px;

    /* Border color */
    --border-color-gray: #d3d3d3;

    /*background Colors */
    --bgcolor-primary: #000000;
    --bgcolor-secondary: #272626;
    --bgcolor-dark-1: #202020;
    --bgcolor-dark-2: #161616;
    --bgcolor-dark-3: #121212;
    --bgcolor-dark-4: #252525;
    --bgcolor-accent: #e74c3c;
    --bgcolor-background: #ecf0f1;
    --bgcolor-grey: #636873;
    --bgcolor-text: #2c3e50;
    --bgcolor-base: #ffffff;
    --bgcolor-light: #fdfdfd;
    --bgcolor-lightgreybg: #fcfcfc;
    --bgcolor-mid-gray: #F6F6F6;
    --bgcolor-lightbluebg: #e3f4ff;

    // Specific Colors
    --primarybtnbg: #ff8d00;
    --secondarybtnbg: var(--nv-primary-accent);
    --primarybtnhoverbg: #ffa638;
    --secondarybtnhoverbg: #29b8ff;
    --lightbluebtnbg: rgba(0, 118, 214, 0.6);
    --lightbluebtn-bg-1: #cdddff;
    --lightbluebtn-bg-2: #66ade6;
    --primarybtncolor: var(--nv-site-bg);
    --secondarybtncolor: var(--nv-text-dark-bg);
    --primarybtnhovercolor: var(--nv-text-dark-bg);
    --secondarybtnhovercolor: var(--nv-text-dark-bg);
    --primarybtnborderradius: 4px;
    --secondarybtnborderradius: 100px;
    --primarybtnhovershadow: 0px 6px 12px rgba(255, 141, 0, 0.26);
    --transparent-bg: transparent;
    --lightorangebtn-bg: rgba(255, 192, 136, 0.6);
    --lightyellowbtn-bg: #fff694;
    --lightgreenbtn-bg: #b6ff9b;
    --link-text-color: #0065ff;
}

//Boostrap Overrides
$theme-colors: (
    'primary': #00AAFF
);